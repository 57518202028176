import {v4 as uuidv4} from "uuid";
import {InteractionFactoryEntity, registerInteraction} from "../../features/interaction_factory/InteractionFactory";
import ScrabbleQuizPreview from "../../features/previews/scrabble_quiz/ScrabbleQuizPreview";
import {GetMigratedProperties} from "../../features/project_migration/InteractionMigrationHelper";
import {requiredGlobalQuizProperties} from "../GlobalQuizProperty";
import {InteractionProperty} from "../InteractionProperty";
import {IAnswer, IFormQuiz} from "../Quiz";
import Interaction, {InteractionType} from "./Interaction";
import Take from "../Take";
import {Actor} from "../Actor";
import {ValidationHelper} from "../../features/validation/ValidationHelper";

export const requiredScrabbleProperties: InteractionProperty[] = [...requiredGlobalQuizProperties];

export function CreateScrabbleQuiz(keys: string[]): IFormQuiz {
    return {
        id: uuidv4(),
        name: "Scrabble Quiz",
        type: InteractionType.ScrabbleQuiz,
        title: keys[0],
        answers: [{id: uuidv4(), correct: false, text: keys[1]}],
        properties: requiredScrabbleProperties,
    };
}

export function CreateScrabbleQuizCopy(titleKey: string, answers: IAnswer[], properties: InteractionProperty[] | undefined): IFormQuiz {
    return {
        id: uuidv4(),
        name: "Scrabble Quiz",
        type: InteractionType.ScrabbleQuiz,
        title: titleKey,
        answers: answers,
        properties: properties,
    };
}

export function MigrateScrabbleQuiz(from: number, to: number, interaction: IFormQuiz): IFormQuiz {
    if (interaction.properties === undefined) return {...interaction, properties: requiredScrabbleProperties};

    var newProperties: InteractionProperty[] = GetMigratedProperties(requiredScrabbleProperties, interaction.properties);
    console.log("[ScrabbleQuiz] migrated " + (newProperties.length - interaction.properties.length) + " properties");

    return {...interaction, properties: newProperties};
}

export const scrabbleQuizInteraction = (): InteractionFactoryEntity => {
    const component = <ScrabbleQuizPreview/>;

    const create = (getKeys: (amount: number) => string[]): IFormQuiz => {
        const keys = getKeys(2);

        return {
            id: uuidv4(),
            name: "Scrabble Quiz",
            type: InteractionType.ScrabbleQuiz,
            title: keys[0],
            answers: [{id: uuidv4(), correct: false, text: keys[1]}],
            properties: requiredScrabbleProperties,
        };
    };
    const migrate = (from: number, to: number, interaction: Interaction): IFormQuiz => {
        const quizData = interaction as IFormQuiz;
        if (interaction.properties === undefined) return {...quizData, properties: requiredScrabbleProperties};

        var newProperties: InteractionProperty[] = GetMigratedProperties(requiredScrabbleProperties, interaction.properties);
        console.log("[ScrabbleQuiz] migrated " + (newProperties.length - interaction.properties.length) + " properties");

        return {...quizData, properties: newProperties};
    };

    const copy = (interaction: Interaction,
                  getKeys: (amount: number) => string[],
                  replaceKey: (from: string, to: string) => void, getAnswerArray: (originalAnswers: IAnswer[], newKeys: string[]) => IAnswer[]): IFormQuiz => {
        const formQuiz = interaction as IFormQuiz;
        const newKeys = getKeys(formQuiz.answers.length + 1);
        replaceKey(formQuiz.title, newKeys[newKeys.length - 1]);
        return {
            id: uuidv4(),
            name: interaction.name,
            type: InteractionType.ScrabbleQuiz,
            title: newKeys[newKeys.length - 1],
            answers: getAnswerArray(formQuiz.answers, newKeys),
            properties: formQuiz.properties,
        };
    }

    const validate = (interaction: Interaction, getValue: (key: string) => string, take: Take, actorPresets: Actor[]): string[] => {
        const converted = interaction as IFormQuiz;
        const warnings: string[] = [];

        ValidationHelper.ValidateKey(converted.title, getValue, warnings, "Title");

        // validate all answers 
        for (let i = 0; i < converted.answers.length; i++) {
            const tempAnswer = converted.answers[i];
            ValidationHelper.ValidateKey(tempAnswer.text, getValue, warnings, `Answer ${i + 1}`);
        }

        return warnings;
    }
    
    return {
        View: component,
        Create: create,
        Migrate: migrate,
        Copy: copy,
        Validate: validate
    };
};

