import { useDispatch, useSelector } from "react-redux";
import { selectToken } from "../app/slices/AuthenticationSlice";
import { setIsLoading } from "../app/slices/GlobalSlice";
import {
  selectLocalizedEntries,
  selectLocalizedLanguages,
  selectTrainingLocalizationState,
} from "../app/slices/TrainingLocalizationSlice";
import {
  selectSelectedTake,
  selectTakes,
  selectTrainingType,
  setIsPlaying,
} from "../app/slices/TrainingSlice";
import { selectCurrentOrganization } from "../app/slices/UserSlice";
import LocalizedLanguageDTO from "../dto/LocalizedLanguageDTO";
import { Training } from "../models/Training";
import { TrainingService } from "../services/TrainingService";
import { useSaveTraining } from "./SaveTrainingHook";
import { UseSerializeLocalization } from "./SerializeLocalizationHook";

export const useTestProject = () => {
  const takes = useSelector(selectTakes);
  const localizedEntries = useSelector(selectLocalizedEntries);
  const addedLanguages = useSelector(selectLocalizedLanguages);
  const dispatch = useDispatch();
  const { serializeLanguageForTesting } = UseSerializeLocalization();
  const locState = useSelector(selectTrainingLocalizationState);
  const orgId = useSelector(selectCurrentOrganization)?.id;
  const token = useSelector(selectToken);
  const selectedTake = useSelector(selectSelectedTake);
  const trainingType = useSelector(selectTrainingType);
  const {
    getSerializedTraining,
    getSerializedTrainingSingleTake,
    getSerializedTrainingFromCurrentTake,
  } = useSaveTraining();

  const getCurrentProjectTestData = async () => {
    if (!orgId) return;
    dispatch(setIsLoading(true));
    var localization: LocalizedLanguageDTO[] = [];
    for (var i = 0; i < locState.localizedLanguages.length; i++) {
      localization.push(
        serializeLanguageForTesting(locState.localizedLanguages[i].shortcode)
      );
    }

    const training = getSerializedTraining();
    const data = {
      takes: training,
      localization: localization,
    };

    // for testing transpiler
    console.log({
      takes: JSON.stringify(training),
      localizations: localization,
    });

    //console.log(JSON.stringify(data));
    //console.log(data);

    var testableEditorData: string = await TrainingService.GetTrainingTestData(
      orgId,
      token,
      data
    );
    //console.log(testableEditorData);

    dispatch(setIsLoading(false));
    dispatch(setIsPlaying({ isPlaying: true, data: testableEditorData }));

    return testableEditorData;
  };

  const testCurrentBubble = async () => {
    if (!orgId) return;
    dispatch(setIsLoading(true));
    var localization: LocalizedLanguageDTO[] = [];
    for (var i = 0; i < locState.localizedLanguages.length; i++) {
      localization.push(
        serializeLanguageForTesting(locState.localizedLanguages[i].shortcode)
      );
    }

    const training: Training = getSerializedTrainingSingleTake();
    const data = {
      takes: training,
      localization: localization,
    };

    var testableEditorData: string = await TrainingService.GetTrainingTestData(
      orgId,
      token,
      data
    );
    //console.log(testableEditorData);

    dispatch(setIsLoading(false));
    dispatch(setIsPlaying({ isPlaying: true, data: testableEditorData }));

    return testableEditorData;
  };

  const testFromCurrentTake = async () => {
    if (!orgId) return;
    dispatch(setIsLoading(true));
    var localization: LocalizedLanguageDTO[] = [];
    for (var i = 0; i < locState.localizedLanguages.length; i++) {
      localization.push(
        serializeLanguageForTesting(locState.localizedLanguages[i].shortcode)
      );
    }

    const training: Training = getSerializedTrainingFromCurrentTake();
    const data = {
      takes: training,
      localization: localization,
    };

    var testableEditorData: string = await TrainingService.GetTrainingTestData(
      orgId,
      token,
      data
    );
    //console.log(testableEditorData);

    dispatch(setIsLoading(false));
    dispatch(setIsPlaying({ isPlaying: true, data: testableEditorData }));

    return testableEditorData;
  };

  return {
    getCurrentProjectTestData,
    testCurrentBubble,
    testFromCurrentTake,
  } as const;
};
