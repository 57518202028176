import { InteractionFactoryEntity } from "../../features/interaction_factory/InteractionFactory";
import ButtonsQuizPreview from "../../features/previews/buttons_quiz/ButtonsQuizPreview";
import { ValidationHelper } from "../../features/validation/ValidationHelper";
import { GetNewGuid } from "../../helpers/GuidHelper";
import { Actor } from "../Actor";
import { IAnswer, IFormQuiz } from "../Quiz";
import Take from "../Take";
import Interaction, { InteractionType } from "./Interaction";

export const buttonsQuizInteraction = (): InteractionFactoryEntity => {
  const component = <ButtonsQuizPreview />;

  const create = (getKeys: (amount: number) => string[]): IFormQuiz => {
    const keys = getKeys(2);

    return {
      id: GetNewGuid(),
      name: "Buttons Quiz",
      type: InteractionType.ButtonsQuiz,
      title: keys[0],
      answers: [{ id: GetNewGuid(), correct: false, text: keys[1] }],
    };
  };

  const migrate = (
    from: number,
    to: number,
    interaction: Interaction
  ): IFormQuiz => {
    return interaction as IFormQuiz;
  };

  const copy = (
    interaction: Interaction,
    getKeys: (amount: number) => string[],
    replaceKey: (from: string, to: string) => void,
    getAnswerArray: (originalAnswers: IAnswer[], newKeys: string[]) => IAnswer[]
  ): IFormQuiz => {
    const formQuiz = interaction as IFormQuiz;
    const newKeys = getKeys(formQuiz.answers.length + 1);
    replaceKey(formQuiz.title, newKeys[newKeys.length - 1]);
    return {
      id: GetNewGuid(),
      name: interaction.name,
      type: InteractionType.MultipleChoiceQuiz,
      title: newKeys[newKeys.length - 1],
      answers: getAnswerArray(formQuiz.answers, newKeys),
      properties: formQuiz.properties,
      assetId: formQuiz.assetId,
    };
  };

  const validate = (
    interaction: Interaction,
    getValue: (key: string) => string,
    take: Take,
    actorPresets: Actor[]
  ): string[] => {
    const converted = interaction as IFormQuiz;
    const warnings: string[] = [];

    ValidationHelper.ValidateKey(converted.title, getValue, warnings, "Title");

    let hasCorrectAnswer = false;

    // validate all answers + validate we have at least one answer
    for (let i = 0; i < converted.answers.length; i++) {
      const tempAnswer = converted.answers[i];
      ValidationHelper.ValidateKey(
        tempAnswer.text,
        getValue,
        warnings,
        `Answer ${i + 1}`
      );
      if (tempAnswer.correct) hasCorrectAnswer = true;
    }

    if (!hasCorrectAnswer) warnings.push("No correct answer selected");

    return warnings;
  };

  return {
    View: component,
    Create: create,
    Migrate: migrate,
    Copy: copy,
    Validate: validate,
  };
};
