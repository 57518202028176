import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { IconButton, Tooltip } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useDebouncedCallback } from "use-debounce";
import "./PiInputField.css";
interface PiInputFieldProps {
  label: string;
  placeholder: string;
  info?: string;
  showInfoButton?: boolean;
  onChange: (newValue: string) => void;
  value?: string;
  disabled?: boolean;
  showSearchIcon?: boolean;
  id?: string;
  autoFocus?: boolean;
}

export default function PiInputfield(props: PiInputFieldProps) {
  const { value, disabled, showSearchIcon, autoFocus } = { ...props };
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!ref.current) {
      return;
    }
    ref.current.value = value ? value : "";
  }, [value]);

  const infoButton = props.showInfoButton ? (
    <div className="pi-inputfield-info">
      <Tooltip title={props.info} arrow placement="right">
        <IconButton tabIndex={-1}>
          <InfoOutlinedIcon />
        </IconButton>
      </Tooltip>
    </div>
  ) : (
    ""
  );

  const searchIcon = (
    <div className="pi-inputfield-searchicon">
      <SearchIcon />
    </div>
  );

  const debounced = useDebouncedCallback((value: string) => {
    props.onChange(value);
  }, 500);

  return (
    <div id={props.id ?? ""} className={disabled ? "pi-inputfield-root pi-inputfield-disabled" : "pi-inputfield-root"}>
      <div className="pi-inputfield-label">{props.label}</div>
      <div className="pi-inputfield-wrapper">
        {showSearchIcon && searchIcon}
        <input
          ref={ref}
          className="pi-inputfield-input"
          defaultValue={value ? value : ""}
          placeholder={props.placeholder}
          onChange={(e) => debounced(e.target.value)}
          onBlur={(e) => {
            if (ref) props.onChange(ref.current?.value!);
          }}
          disabled={disabled}
          autoFocus={autoFocus}
        />
        {infoButton}
      </div>
    </div>
  );
}
