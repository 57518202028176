import { InteractionFactoryEntity } from "../../features/interaction_factory/InteractionFactory";
import LookatPreview from "../../features/previews/lookat/LookatPreview";
import { GetNewGuid } from "../../helpers/GuidHelper";
import { Actor } from "../Actor";
import { IAnswer } from "../Quiz";
import Take from "../Take";
import Interaction, { InteractionType } from "./Interaction";

export interface ILookat extends Interaction {
  sourceNpcId: string;
  targetId: string;
}

export const lookatInteraction = (): InteractionFactoryEntity => {
  const view = <LookatPreview />;
  const create = (getKeys: (amount: number) => string[]): ILookat => {
    return {
      id: GetNewGuid(),
      name: "Look At",
      type: InteractionType.Lookat,
      sourceNpcId: "",
      targetId: "",
    };
  };

  const migrate = (
    from: number,
    to: number,
    interaction: Interaction
  ): ILookat => {
    return interaction as ILookat;
  };

  const copy = (
    interaction: Interaction,
    getKeys: (amount: number) => string[],
    replaceKey: (from: string, to: string) => void,
    getAnswerArray: (originalAnswers: IAnswer[], newKeys: string[]) => IAnswer[]
  ): ILookat => {
    const fromLookat = interaction as ILookat;

    return {
      id: GetNewGuid(),
      name: fromLookat.name,
      type: fromLookat.type,
      sourceNpcId: fromLookat.sourceNpcId,
      targetId: fromLookat.targetId,
    };
  };

  const validate = (
    interaction: Interaction,
    getValue: (key: string) => string,
    take: Take,
    actorPresets: Actor[]
  ): string[] => {
    const converted = interaction as ILookat;
    const warnings: string[] = [];

    if (!converted.sourceNpcId) warnings.push("Npc Id is empty");
    if (!converted.targetId) warnings.push("Target Id is empty");

    return warnings;
  };

  return {
    View: view,
    Create: create,
    Migrate: migrate,
    Validate: validate,
    Copy: copy,
  };
};
