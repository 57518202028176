import PiPopupWindowPreview from "../components/PiPopupWindowPreview";
import PiPopupWindowStaticTitle from "../components/PiPopupWindowStaticTitle";
import {useDispatch, useSelector} from "react-redux";
import {selectSelectedElement, updateSelectedInteraction} from "../../../app/slices/TrainingSlice";
import {IAttach} from "../../../models/interactions/Attach";
import MessageBubbleActorDropdown from "../message_bubble/MessageBubbleActorDropdown";
import React from "react";
import PropDropdown from "../grab/PropDropdown";
import AttachPropDropdown from "./AttachPropDropdown";
import {Prop} from "../../../models/Prop";

export default function AttachPreview() {
    return <PiPopupWindowPreview body={<AttachPreviewBody/>} title={<PiPopupWindowStaticTitle title={"Attach"}/>}/>
}

function AttachPreviewBody() {
    const attach = useSelector(selectSelectedElement) as IAttach;
    const dispatch = useDispatch();

    const onChange = (field: keyof IAttach, value: any) => {
        dispatch(updateSelectedInteraction({...attach, [field]: value}));
    }

    const onChangeDropdown = (prop: Prop) => {
        dispatch(updateSelectedInteraction({...attach, propGuid: prop.id, prefabName: prop.prefabName} as IAttach));
    }

    const actorDropdown = <MessageBubbleActorDropdown label={"Actor"} selectedTargetActorId={attach.playerGuid}
                                                      onClick={(actorId) => onChange("playerGuid", actorId)}
                                                      hideActorsWithProp={true}/>;

    // TODO: we need to list all the props that are not spawned :) 
    const propDropdown = <PropDropdown label={"Prop"} selectedPropStateId={attach.propGuid}
                                       onClick={(propId) => onChange("propGuid", propId)}/>;

    const attachDropdown = <AttachPropDropdown selectedPropId={attach.propGuid} onChange={onChangeDropdown}/>

    return <div className={"w-full h-full flex gap-x-[14px]"}>
        {actorDropdown}
        {attachDropdown}
    </div>
}