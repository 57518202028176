import {v4 as uuidv4} from "uuid";
import Interaction, {InteractionType} from "./Interaction";
import {InteractionFactoryEntity, registerInteraction} from "../../features/interaction_factory/InteractionFactory";
import MessagePopupPreview from "../../features/previews/MessagePopupPreview";
import Take from "../Take";
import {Actor} from "../Actor";
import {ValidationHelper} from "../../features/validation/ValidationHelper";
import {IDialogQuiz} from "./DialogQuiz";


export interface MessagePopup extends Interaction {
    message: string;
}

export function CreateMessagePopup(messageKey: string): MessagePopup {
    return {
        id: uuidv4(),
        name: "Message Popup",
        type: InteractionType.MessagePopup,
        message: messageKey,
    };
}

export function MigrateMessagePopup(from: number, to: number, interaction: MessagePopup): MessagePopup {
    return interaction;
}


export const messagePopupInteraction = (): InteractionFactoryEntity => {
    const component = <MessagePopupPreview/>;
    const create = (getKeys: (amount: number) => string[]): MessagePopup => {
        const keys = getKeys(1);

        return {
            id: uuidv4(),
            name: "Message Popup",
            type: InteractionType.MessagePopup,
            message: keys[0],
        };
    }

    const migrate = (from: number, to: number, interaction: Interaction): MessagePopup => {
        return interaction as MessagePopup;
    }

    const copy = (interaction: Interaction, getKeys: (amount: number) => string[], replaceKey: (from: string, to: string) => void): MessagePopup => {
        const newKeys = getKeys(1);
        replaceKey((interaction as MessagePopup).message, newKeys[0]);

        return {
            ...interaction,
            id: uuidv4(),
            message: newKeys[0],
        };
    }

    const validate = (interaction: Interaction, getValue: (key: string) => string, take: Take, actorPresets: Actor[]): string[] => {
        const converted = interaction as MessagePopup;
        const warnings: string[] = [];

        ValidationHelper.ValidateKey(converted.message, getValue, warnings, "Message");

        return warnings;
    }
    
    return {
        View: component,
        Create: create,
        Migrate: migrate,
        Copy: copy,
        Validate: validate
    }
}



