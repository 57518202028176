import { InteractionFactoryEntity, registerInteraction } from "../../features/interaction_factory/InteractionFactory";
import GrabPreview from "../../features/previews/grab/GrabPreview";
import { GetNewGuid } from "../../helpers/GuidHelper";
import Interaction, { InteractionType } from "./Interaction";

export interface IGrab extends Interaction {
  playerGuid: string;
  propGuid: string;
}

export function CreateGrab(playerGuid?: string, propGuid?: string): IGrab {
  return {
    id: GetNewGuid(),
    name: "Grab",
    type: InteractionType.Grab,
    playerGuid: playerGuid ?? "player",
    propGuid: propGuid ?? "",
  };
}

export const grabInteraction = (): InteractionFactoryEntity => {
  const component = <GrabPreview />;

  const create = (getKeys: (amount: number) => string[]): IGrab => {
    return {
      id: GetNewGuid(),
      name: "Grab",
      type: InteractionType.Grab,
      playerGuid: "",
      propGuid: "",
    };
  };
  
  const migrate = (from: number, to: number, interaction: Interaction): IGrab => {
    return interaction as IGrab;
  };
  
  const copy = (interaction: Interaction, getKeys: (amount: number) => string[], replaceKey: (from: string, to: string) => void): IGrab => {
    return create(getKeys);
  }
  
  return {
    View: component,
    Create: create,
    Migrate: migrate,
    Copy: copy
  };
};

