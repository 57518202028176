import {v4 as uuidv4} from "uuid";
import Interaction, {InteractionType, Marker} from "./Interaction";
import {InteractionFactoryEntity, registerInteraction} from "../../features/interaction_factory/InteractionFactory";
import AmbientAudioPreview from "../../features/previews/ambient_audio/AmbientAudioPreview";
import {IAnswer} from "../Quiz";
import Take from "../Take";
import {Actor} from "../Actor";
import {ValidationHelper} from "../../features/validation/ValidationHelper";
import {IAudioPopup} from "./AudioPopup";

export interface IAmbientAudio extends Interaction {
    assetId: string;
}

export function CreateAmbientAudio(assetId?: string): IAmbientAudio {
    return {
        id: uuidv4(),
        name: "Ambient Audio",
        type: InteractionType.AmbientAudio,
        assetId: assetId ?? "",
    };
}

export function MigrateAmbientAudio(from: number, to: number, interaction: IAmbientAudio): IAmbientAudio {
    return interaction;
}

export const ambientAudioInteraction = (): InteractionFactoryEntity => {
    const component = <AmbientAudioPreview/>;

    const create = (getKeys: (amount: number) => string[]): IAmbientAudio => {
        return {
            id: uuidv4(),
            name: "Ambient Audio",
            type: InteractionType.AmbientAudio,
            assetId: "",
        };
    }
    const migrate = (from: number, to: number, interaction: Interaction): IAmbientAudio => {
        return interaction as IAmbientAudio;
    }

    const copy = (interaction: Interaction,
                  getKeys: (amount: number) => string[],
                  replaceKey: (from: string, to: string) => void, getAnswerArray: (originalAnswers: IAnswer[], newKeys: string[]) => IAnswer[]): IAmbientAudio => {
        return {...create(getKeys), assetId: (interaction as IAmbientAudio).assetId};
    }

    const validate = (interaction: Interaction, getValue: (key: string) => string, take: Take, actorPresets: Actor[]): string[] => {
        const converted = interaction as IAmbientAudio;
        const warnings: string[] = [];

        ValidationHelper.ValidateAssetId(converted.assetId, warnings);

        return warnings;
    }
    
    return {
        View: component,
        Create: create,
        Migrate: migrate,
        Copy: copy,
        Validate: validate
    }
}
