import { useDispatch, useSelector } from "react-redux";
import { selectToken } from "../app/slices/AuthenticationSlice";
import { setIsLoading } from "../app/slices/GlobalSlice";
import { selectCurrentTrainingId } from "../app/slices/ProjectsSlice";
import {
  selectLocalizedLanguages,
  selectTrainingLocalizationState,
} from "../app/slices/TrainingLocalizationSlice";
import {
  resetIsDirty,
  selectActors,
  selectAllPropPresets,
  selectReferenceLanguage,
  selectSelectedTake,
  selectSelectedTake3D,
  selectTakes,
  selectTakes3D,
  selectTrainingType,
} from "../app/slices/TrainingSlice";
import { selectCurrentOrganization } from "../app/slices/UserSlice";
import { Training } from "../models/Training";
import { TrainingService } from "../services/TrainingService";
import { UseSerializeLocalization } from "./SerializeLocalizationHook";
import { selectCurrentEnvironmentId } from "../app/slices/EnvironmentSlice";

export const useSaveTraining = () => {
  const dispatch = useDispatch();
  const takes = useSelector(selectTakes);
  const takes3d = useSelector(selectTakes3D);
  const selectedTake3d = useSelector(selectSelectedTake3D);
  const selectedTake360 = useSelector(selectSelectedTake);
  const currentTrainingId = useSelector(selectCurrentTrainingId);
  const orgId = useSelector(selectCurrentOrganization)?.id;
  const token = useSelector(selectToken);
  const locState = useSelector(selectTrainingLocalizationState);
  const refLanguage = useSelector(selectReferenceLanguage);
  const languages = useSelector(selectLocalizedLanguages);
  const { serializeLanguage } = UseSerializeLocalization();
  const trainingType = useSelector(selectTrainingType);
  const actors = useSelector(selectActors);
  const props = useSelector(selectAllPropPresets);

  const currentEnvironmentId = useSelector(selectCurrentEnvironmentId);

  var targetVersion = Number(process.env.REACT_APP_PLAYIT_TRAINING_VERSION);

  const saveTraining = async () => {
    if (!orgId || currentTrainingId === "") return;
    dispatch(setIsLoading(true));

    const content = JSON.stringify(getSerializedTraining());

    // saving training content
    await TrainingService.CreateNewTrainingVersion(
      orgId,
      token,
      currentTrainingId,
      content
    );

    // we need to do this for each language thats not created yet
    for (var i = 0; i < locState.localizedLanguages.length; i++) {
      var data = JSON.stringify(
        serializeLanguage(locState.localizedLanguages[i].shortcode)
      );
      await TrainingService.UpdateTrainingTranslation(
        orgId,
        token,
        currentTrainingId,
        locState.localizedLanguages[i].shortcode,
        data
      );
    }
    dispatch(resetIsDirty());
    dispatch(setIsLoading(false));
  };

  const getSerializedTraining = () => {
    if (trainingType === 0) {
      var training3d: Training = {
        refLanguage: refLanguage !== "" ? refLanguage : languages[0].shortcode,
        takes: takes3d,
        trainingType: 0,
        version: targetVersion - 1,
        metadata: {
          environmentId: currentEnvironmentId,
          actors: actors,
          props: props,
        },
      };
      return training3d;
    } else if (trainingType === 1) {
      var training: Training = {
        refLanguage: refLanguage !== "" ? refLanguage : languages[0].shortcode,
        trainingType: 1,
        takes: takes,
        version: targetVersion,
      };
      return training;
    }
  };

  const getSerializedTrainingSingleTake = () => {
    if (trainingType === 0) {
      var training3d: Training = {
        refLanguage: refLanguage !== "" ? refLanguage : languages[0].shortcode,
        takes: [selectedTake3d!],
        trainingType: 0,
        version: targetVersion - 1,
        metadata: {
          environmentId: currentEnvironmentId,
          actors: actors,
          props: props,
        },
      };
      return training3d;
    } else {
      var training: Training = {
        refLanguage: refLanguage !== "" ? refLanguage : languages[0].shortcode,
        trainingType: 1,
        takes: [selectedTake360!],
        version: targetVersion,
      };
      return training;
    }
  };

  const getSerializedTrainingFromCurrentTake = () => {
    if (trainingType === 0) {
      const startIndex = takes3d.findIndex((t) => t.id === selectedTake3d!.id);
      const trainingTakes = takes3d.slice(startIndex);
      console.log("amount of takes: " + trainingTakes.length);
      var training3d: Training = {
        refLanguage: refLanguage !== "" ? refLanguage : languages[0].shortcode,
        takes: trainingTakes,
        trainingType: 0,
        version: targetVersion - 1,
        metadata: {
          environmentId: currentEnvironmentId,
          actors: actors,
          props: props,
        },
      };
      return training3d;
    } else {
      const startIndex = takes.findIndex((t) => t.id === selectedTake360!.id);
      const trainingTakes = takes.slice(startIndex);

      var training: Training = {
        refLanguage: refLanguage !== "" ? refLanguage : languages[0].shortcode,
        trainingType: 1,
        takes: trainingTakes,
        version: targetVersion,
      };
      return training;
    }
  };

  return {
    saveTraining,
    getSerializedTraining,
    getSerializedTrainingSingleTake,
    getSerializedTrainingFromCurrentTake,
  } as const;
};
