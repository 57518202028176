import {v4 as uuidv4} from "uuid";
import {InteractionFactoryEntity, registerInteraction} from "../../features/interaction_factory/InteractionFactory";
import VideoPopupPreview from "../../features/previews/VideoPopupPreview";
import {GetMigratedProperties} from "../../features/project_migration/InteractionMigrationHelper";
import {InteractionProperty} from "../InteractionProperty";
import Interaction, {InteractionType} from "./Interaction";
import Take from "../Take";
import {Actor} from "../Actor";
import Take3D from "../Take3D";
import {ValidationHelper} from "../../features/validation/ValidationHelper";
import {IEmotionBubble} from "./EmotionBubble";

export interface VideoPopup extends Interaction {
    title: string;
    assetId: string;
}

export const requiredVideoProperties: InteractionProperty[] = [];

export function CreateVideoPopup(titleKey: string, assetId?: string): VideoPopup {
    return {
        id: uuidv4(),
        name: "Video Popup",
        type: InteractionType.VideoPopup,
        title: titleKey,
        assetId: assetId ?? "",
        properties: requiredVideoProperties,
    };
}

export function MigrateVideoPopup(from: number, to: number, interaction: VideoPopup): VideoPopup {
    if (interaction.properties === undefined) return {...interaction, properties: requiredVideoProperties};

    // drill the props and add any missing ones
    var newProperties: InteractionProperty[] = GetMigratedProperties(requiredVideoProperties, interaction.properties);
    console.log("[VideoPopup] migrated " + (newProperties.length - interaction.properties.length) + " properties");

    return {...interaction, properties: newProperties};
}

export const videoPopupInteraction = (): InteractionFactoryEntity => {
    const component = <VideoPopupPreview/>;

    const create = (getKeys: (amount: number) => string[]): VideoPopup => {
        const keys = getKeys(1);
        return {
            id: uuidv4(),
            name: "Video Popup",
            type: InteractionType.VideoPopup,
            title: keys[0],
            assetId: "",
            properties: requiredVideoProperties,
        };
    };

    const migrate = (from: number, to: number, interaction: Interaction): VideoPopup => {
        if (interaction.properties === undefined) return {...(interaction as VideoPopup), properties: requiredVideoProperties};

        // drill the props and add any missing ones
        var newProperties: InteractionProperty[] = GetMigratedProperties(requiredVideoProperties, interaction.properties);
        console.log("[VideoPopup] migrated " + (newProperties.length - interaction.properties.length) + " properties");

        return {...(interaction as VideoPopup), properties: newProperties};
    };

    const copy = (interaction: Interaction, getKeys: (amount: number) => string[], replaceKey: (from: string, to: string) => void): VideoPopup => {
        const key = getKeys(1)[0];
        replaceKey((interaction as VideoPopup).title, key);
        return {
            id: uuidv4(),
            name: "Video Popup",
            type: InteractionType.VideoPopup,
            title: key,
            assetId: (interaction as VideoPopup).assetId,
            properties: requiredVideoProperties,

        }
    }

    const validate = (interaction: Interaction, getValue: (key: string) => string, take: Take, actorPresets: Actor[]): string[] => {
       const converted = interaction as VideoPopup;
        const warnings: string[] = [];

        ValidationHelper.ValidateKey(converted.title, getValue, warnings, "Title");
        ValidationHelper.ValidateAssetId(converted.assetId, warnings);
        
        return warnings;
    }
    
    return {
        View: component,
        Create: create,
        Migrate: migrate,
        Copy: copy,
        Validate: validate
    };
};

