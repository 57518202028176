import React, {useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectSelectedTake3D, updateSelectedTake, updateSelectedTakeProperty} from "../../app/slices/TrainingSlice";
import {InteractionProperty} from "../../models/InteractionProperty";
import InteractionPropertyView from "../interaction_properties_column/InteractionPropertyView";
import PropertiesColumnHeader from "../properties_column/PropertiesColumnHeader";
import PiCollapsibleSettings from "../../components/PiCollapsibleSettings";
import MovieCreationOutlinedIcon from '@mui/icons-material/MovieCreationOutlined';
import PiInputfield from "../../components/PiInputField";
import {Trans, useTranslation} from "react-i18next";
import Take from "../../models/Take";
import TakePropertiesActorList from "./TakePropertiesActorList";
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import PropertiesColumnBody from "../properties_column/PropertiesColumnBody";
import TakePropertiesPropList from "./TakePropertiesPropList";
import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined';
import {useTrainingLocalization} from "../trainingLocalization/TrainingLocalizationHooks";
import {AnimatePresence, motion} from "framer-motion";
import Chrome from "@uiw/react-color-chrome";
import PiColorPicker from "../../components/PiColorPicker";
import PiColorPickerField from "../../components/PiColorPickerField";
import {RgbaColor} from "@uiw/react-color";
import Take3D from "../../models/Take3D";
import PiAssetSelect from "../../components/PiAssetSelect";

export default function Take3DPropertiesColumn() {
    const dispatch = useDispatch();
    const take = useSelector(selectSelectedTake3D);
    const transition = take!.transition;
    const properties = take?.properties;
    const {t} = useTranslation();
    const {getValue, changeValue} = useTrainingLocalization();


    const onChangeTakeName = (newName: string) => {
        dispatch(updateSelectedTake({...take, name: newName} as Take));
    }

    const onChange = (interactionProperty: InteractionProperty) => {
        dispatch(updateSelectedTakeProperty(interactionProperty));
    };

    const onChangeTransitionColor = (color: RgbaColor) => {
        if (!take) return;
        dispatch(updateSelectedTake({...take, transition: {...take.transition, color: color}} as Take3D))
    }

    const onChangeTransitionIcon = (assetId: string) => {
        if (!take) return;
        dispatch(updateSelectedTake({...take, transition: {...take.transition, assetId: assetId}} as Take3D))
    }

    const takeName = <>
        <PiInputfield label={t("take_name")} value={take?.name ?? ""} placeholder={"Take Name"}
                      onChange={onChangeTakeName}/>
    </>;

    const propMap = properties?.map((property, index) => {
        return <InteractionPropertyView key={index} interactionProperty={property} onChange={onChange}/>;
    });

    const takeTab = <PiCollapsibleSettings text={t("take")}
                                           icon={<MovieCreationOutlinedIcon color={"inherit"} fontSize={"inherit"}/>}
                                           paddingX paddingY>
        {takeName}
    </PiCollapsibleSettings>;


    const storyProp = properties?.find(p => p.id === "isStory");
    const storyView = useMemo(() => {
        return storyProp ?
            <InteractionPropertyView interactionProperty={storyProp} onChange={onChange}/> : <></>
    }, [take, take?.id]);

    const camTab = <PiCollapsibleSettings text={t("camera")}
                                          icon={<CameraAltOutlinedIcon color={"inherit"} fontSize={"inherit"}/>}
                                          paddingX paddingY>
        {storyView}
    </PiCollapsibleSettings>

    const transitionTab = <PiCollapsibleSettings text={t("transition")}
                                                 icon={<ChangeCircleOutlinedIcon color={"inherit"}
                                                                                 fontSize={"inherit"}/>} paddingX
                                                 paddingY>
        <PiInputfield label={t("text")} placeholder={"Transition"} value={getValue(transition.text)}
                      onChange={(newValue: string) => changeValue(transition.text, newValue)}/>
        <PiColorPickerField label={"Color"} color={take!.transition.color} onChange={onChangeTransitionColor}/>
        <PiAssetSelect label={"Icon"} assetId={transition.assetId} onChange={onChangeTransitionIcon}/>
    </PiCollapsibleSettings>


    return (
        <>
            <PropertiesColumnHeader text={take?.name ?? "Take"}/>
            <PropertiesColumnBody>
                {takeTab}
                {camTab}
                <AnimatePresence>
                    {storyProp!.value === true &&
                        <motion.div initial={{opacity: 0, height: "0%"}}
                                    animate={{opacity: 1, height: "auto"}}
                                    exit={{opacity: 0, height: "0%"}}
                                    transition={{
                                        duration: 0.15,
                                        ease: "easeInOut"
                                    }}>
                            {transitionTab}
                        </motion.div>}
                </AnimatePresence>
                <TakePropertiesActorList/>
                <TakePropertiesPropList/>
            </PropertiesColumnBody>
        </>
    );
}
