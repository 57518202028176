import {IAnswer, IFormQuiz} from "../Quiz";
import Interaction, {InteractionType} from "./Interaction";
import {v4 as uuidv4} from "uuid";

import {InteractionFactoryEntity, registerInteraction} from "../../features/interaction_factory/InteractionFactory";

import DialogQuizPreview from "../../features/previews/dialog_quiz/DialogQuizPreview";
import Take from "../Take";
import {Actor} from "../Actor";
import {ValidationHelper} from "../../features/validation/ValidationHelper";

export interface IDialogQuiz extends IFormQuiz {
    playerGuid: string;
}

export function CreateDialogQuiz(keys: string[]): IDialogQuiz {
    return {
        id: uuidv4(),
        name: "Dialog Quiz",
        type: InteractionType.DialogQuiz,
        title: keys[0],
        answers: [
            {id: uuidv4(), correct: true, text: keys[1]},
            {id: uuidv4(), correct: false, text: keys[2]},
        ],
        playerGuid: "player",
    };
}

export function CreateDialogQuizCopy(titleKey: string, answers: IAnswer[], playerGuid: string): IDialogQuiz {
    return {
        id: uuidv4(),
        name: "Dialog Quiz",
        type: InteractionType.DialogQuiz,
        title: titleKey,
        answers: answers,
        playerGuid: playerGuid,
    };
}

export function MigrateDialogQuiz(from: number, to: number, interaction: IDialogQuiz): IDialogQuiz {
    return interaction;
}

export const dialogQuizInteraction = (): InteractionFactoryEntity => {

    const component = <DialogQuizPreview/>;

    const create = (getKeys: (amount: number) => string[]): IDialogQuiz => {
        const keys = getKeys(3);
        return {
            id: uuidv4(),
            name: "Dialog Quiz",
            type: InteractionType.DialogQuiz,
            title: keys[0],
            answers: [
                {id: uuidv4(), correct: true, text: keys[1]},
                {id: uuidv4(), correct: false, text: keys[2]},
            ],
            playerGuid: "player",
        };
    };
    const migrate = (from: number, to: number, interaction: Interaction): IDialogQuiz => {
        return interaction as IDialogQuiz;
    }

    const copy = (interaction: Interaction,
                  getKeys: (amount: number) => string[],
                  replaceKey: (from: string, to: string) => void, getAnswerArray: (originalAnswers: IAnswer[], newKeys: string[]) => IAnswer[]): IDialogQuiz => {
        const formQuiz = interaction as IDialogQuiz;
        const newKeys = getKeys(formQuiz.answers.length + 1);
        replaceKey(formQuiz.title, newKeys[newKeys.length - 1]);
        return {
            id: uuidv4(),
            name: interaction.name,
            type: InteractionType.DialogQuiz,
            title: newKeys[newKeys.length - 1],
            answers: getAnswerArray(formQuiz.answers, newKeys),
            playerGuid: formQuiz.playerGuid,
            properties: formQuiz.properties,
        };
    }

    const validate = (interaction: Interaction, getValue: (key: string) => string, take: Take, actorPresets: Actor[]): string[] => {
        const converted = interaction as IDialogQuiz;
        const warnings: string[] = [];

        ValidationHelper.ValidateKey(converted.title, getValue, warnings, "Title");
        
        let hasCorrectAnswer = false;

        // validate all answers + validate we have at least one answer
        for (let i = 0; i < converted.answers.length; i++) {
            const tempAnswer = converted.answers[i];
            ValidationHelper.ValidateKey(tempAnswer.text, getValue, warnings, `Answer ${i + 1}`);
            ValidationHelper.ValidateKey(tempAnswer.text, getValue, warnings, `Answer ${i + 1}`);
            if (tempAnswer.correct) hasCorrectAnswer = true;
        }

        if (!hasCorrectAnswer)
            warnings.push("No correct answer selected");

        return warnings;
    }
    
    return {
        View: component,
        Create: create,
        Migrate: migrate,
        Copy: copy,
        Validate: validate
    }
}


